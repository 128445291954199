import { writable } from 'svelte/store';
import { v4 as uuid } from 'uuid';

export type Toast = {
  id: string;
  message: string;
  duration: number;
};

export enum ToastLocation {
  Top = 'top',
  Bottom = 'bottom',
}

function createToast() {
  const items = writable<Toast[]>([]);

  /**
   * Push a new toast to the current list of active toasts
   * @param message
   * @param duration
   */
  function push(message: string, duration = 5000) {
    items.update((toasts) => [...toasts, { id: uuid(), duration, message }]);
  }

  /**
   * Remove a toast from the current list of active toasts
   * @param {string} id
   */
  function pop(id: string) {
    items.update((toasts) => toasts.filter((toast) => toast.id !== id));
  }

  return { items, push, pop };
}

export const toast = createToast();
