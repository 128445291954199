export enum KeyboardLayoutBaseType {
  'QWERTY' = 'qwerty',
  'AZERTY' = 'azerty',
}

export enum KeyboardLayoutExtendedType {
  'QWERTY_ENTER' = 'qwertyEnter',
  'AZERTY_ENTER' = 'azertyEnter',
}
export type KeyboardLayoutType = KeyboardLayoutBaseType | KeyboardLayoutExtendedType;

export type KeyboardLayout = string[][];
export type KeyboardLayouts = Record<KeyboardLayoutType, KeyboardLayout>;
export type KeyboardKeyColors = { color?: string; backgroundColor?: string };

export enum Keys {
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40,
  Escape = 27,
  Space = 32,
  Backspace = 8,
  Delete = 46,
  Enter = 13,
  Zero = 48,
  One = 49,
  Two = 50,
  Three = 51,
  Four = 52,
  Five = 53,
  Six = 54,
  Seven = 55,
  Eight = 56,
  Nine = 57,
  A = 65,
  B = 66,
  C = 67,
  D = 68,
  E = 69,
  F = 70,
  G = 71,
  H = 72,
  I = 73,
  J = 74,
  K = 75,
  L = 76,
  M = 77,
  N = 78,
  O = 79,
  P = 80,
  Q = 81,
  R = 82,
  S = 83,
  T = 84,
  U = 85,
  V = 86,
  W = 87,
  X = 88,
  Y = 89,
  Z = 90,
  Tab = 9,
}

export function isNumericalKey(evt: KeyboardEvent) {
  return !!evt.key.match(/^[0-9]$/);
}

export function isAlphabeticalKey(evt: KeyboardEvent) {
  return !!evt.key.match(/^[a-z]$/i);
}

export function isKeyRange(keyCode: number, a: Keys, b: Keys) {
  return keyCode >= a && keyCode <= b;
}

export function isControlZ(keyCode: number, evt: KeyboardEvent) {
  return String.fromCharCode(keyCode) === 'Z' && isShortcut(evt) && !evt.shiftKey;
}

export function isShortcut(evt: KeyboardEvent) {
  return evt.ctrlKey || evt.metaKey;
}

export const keyboards: KeyboardLayouts = {
  qwerty: [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    ['Z', 'X', 'C', 'V', 'B', 'N', 'M', Keys[Keys.Backspace]],
  ],
  qwertyEnter: [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', Keys[Keys.Backspace]],
    ['Z', 'X', 'C', 'V', 'B', 'N', 'M', Keys[Keys.Enter]],
  ],
  azerty: [
    ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    ['W', 'X', 'C', 'V', 'B', 'N', 'M', Keys[Keys.Backspace]],
  ],
  azertyEnter: [
    ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', Keys[Keys.Backspace]],
    ['W', 'X', 'C', 'V', 'B', 'N', 'M', Keys[Keys.Enter]],
  ],
};

/**
 * Checks for any keyCode values that have the same value for multiple keyCodes, like numpad and digits keys,
 * and maps them to a single value.
 * @param keyCode {number} - The keyCode of a keyboard key.
 */
export function mapDuplicateKeyCode(keyCode: number) {
  switch (keyCode) {
    case 96:
      return Keys.Zero;
    case 97:
      return Keys.One;
    case 98:
      return Keys.Two;
    case 99:
      return Keys.Three;
    case 100:
      return Keys.Four;
    case 101:
      return Keys.Five;
    case 102:
      return Keys.Six;
    case 103:
      return Keys.Seven;
    case 104:
      return Keys.Eight;
    case 105:
      return Keys.Nine;
    default:
      return keyCode;
  }
}
