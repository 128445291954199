/*
 * Only load polyfills that are needed by the browser dynamically
 */
export async function loadPolyfills() {
  const polyfills: Promise<any>[] = [];

  if (!('fetch' in window)) {
    // @ts-ignore
    polyfills.push(import(/* webpackChunkName: "whatwg-fetch" */ 'whatwg-fetch'));
  }

  if (!('scrollBehavior' in document.documentElement.style)) {
    polyfills.push(import(/* webpackChunkName: "scroll-behavior" */ 'scroll-behavior-polyfill'));
  }

  if (!('registerElement' in document)) {
    polyfills.push(
      import(/* webpackChunkName: "webcomponentsjs" */ '@webcomponents/webcomponentsjs')
    );
  }

  await Promise.all(polyfills);
}
