export enum Guidelines {
  On = 'on',
  Off = 'off',
  Disabled = 'disabled',
}

export enum TimerConfig {
  On = 'on',
  Off = 'off',
  /** timer is on, can be turned off in the settings */
  OptOut = 'opt-out',
  /** timer is off, can be turned on in the settings, game gives a prompt to enable */
  OptIn = 'opt-in',
}

export enum TimerPreferences {
  On = 'on',
  Off = 'off',
  NotSet = 'notSet',
}

export enum LoaderTypes {
  FortyTwoPuzzles = './img/loaders/42puzzles-transparent.gif',
}

export enum Language {
  nl = 'nl',
  en = 'en',
  es = 'es',
  de = 'de',
}

export enum All {
  all = 'all',
}

export enum GameMenuActionTypes {
  action = 'action',
  check = 'check',
  clean = 'clean',
  dynamicForm = 'dynamicForm',
  feedback = 'feedback',
  fullscreen = 'fullscreen',
  guidelines = 'guidelines',
  help = 'help',
  hint = 'hint',
  multiplayer = 'multiplayer',
  other = 'other',
  print = 'print',
  quit = 'quit',
  readArticle = 'readArticle',
  reload = 'reload',
  remove = 'remove',
  restart = 'restart',
  share = 'share',
  shuffle = 'shuffle',
  stats = 'stats',
  undo = 'undo',
  zoom = 'zoom',
}

export type GameButtonsVisibilityType = GameMenuActionTypes | All;

export enum AuthTypes {
  None = 'none',
  LoggedIn = 'loggedIn',
  Subscribed = 'subscribed',
}

export enum TextDirections {
  LTR = 'ltr',
  RTL = 'rtl',
}

export enum PlayerState {
  Initializing = 'initializing',
  Ready = 'ready',
  Menu = 'menu',
  Playing = 'playing',
  Paused = 'paused',
  Completed = 'finished',
  Locked = 'locked',
}
